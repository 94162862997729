<template>
    <el-table class="bank-files-container" :data="tableData" size="mini">
        <el-table-column prop="person" label="Person" />
        <el-table-column prop="civicNumber" label="Number" />
        <el-table-column prop="companyName" label="Company" />
        <el-table-column prop="registrationNumber" label="Registration Number" />
        <el-table-column prop="created" label="Datum" />
        <el-table-column label="Har PDF">
            <template slot-scope="scope">
                <p v-if="scope.row.hasK10Pdf">Ja</p>
                <p v-else>Nej</p>
            </template>
        </el-table-column>
        <el-table-column label="Vill ha hjälp">
            <template slot-scope="scope">
                <p v-if="scope.row.wantHelp === 0">Ej valt</p>
                <p v-if="scope.row.wantHelp === 1">Ja</p>
                <p v-if="scope.row.wantHelp === 2">Nej</p>
            </template>
        </el-table-column>
        <el-table-column label="Status">
            <template slot-scope="scope">
                <p>{{ getStatusName(scope.row.status) }}</p>
            </template>
        </el-table-column>
        <el-table-column width="50" align="right">
            <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Detaljer" placement="left">
                    <router-link :to="'k10_declaration/' + scope.row.id">
                        <el-button type="primary" class="p-1">
                            <i class="far fa-magnifying-glass-plus" />
                        </el-button>
                    </router-link>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        getStatusName(statusId) {
            const names = {
                0: "Skapad",
                1: "Utkast",
                2: "Inskickad",
                3: "Behandlas",
                4: "Klar",
                5: "Fakturerad",
                6: "Ingen hjälp",
                7: "Ej genomförbar",
            };

            return names[statusId];
        },
    },
};
</script>
